import React, { Component } from "react";
import {
  Checkbox,
  ComboBox,
  Div,
  I,
  If,
  Image,
  Input,
  InputCep,
  Label,
  ModalNotification,
  SelectValue,
} from "../../components";
import { Color, Http, Mask, Type, Utils } from "../../utilities";

import axios from "axios";

import ClienteLogin from "./ClienteLogin";
import UsuarioUtils from "../utils/UsuarioUtils";
import { EmpresaUtils } from "..";

const PATH_VIA_CEP = "https://viacep.com.br/ws/%s/json";

const styles = {
  input: {
    width: "100%",
    border: "none",
    textAlign: "center",
    height: 50,
    backgroundColor: "transparent",
    borderBottom: "1px solid #C4C4C4",
  },
  input50: {
    width: "50%",
    border: "none",
    textAlign: "center",
    backgroundColor: "transparent",
    marginTop: 30,
    borderBottom: "1px solid #C4C4C4",
  },
  input50Disabled: {
    width: "50%",
    border: "none",
    textAlign: "center",
    marginTop: 30,
    borderBottom: "1px solid #C4C4C4",
    cursor: "no-drop",
  },
  containerContinuar: {
    backgroundColor: Color.ECOMMERCE.CINZA,
    color: "white",
    padding: 12,
  },
  containerContinuarActive: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: "white",
    padding: 12,
  },
  containerBottom: {
    position: "fixed",
    bottom: 0,
    zIndex: 1002,
    width: "100%",
  },
  containerCheckBox: {
    backgroundColor: "#f2f2f2",
    padding: 20,
    borderRadius: 10,
    marginTop: 15,
  },
  labelDescricaoActive: {
    backgroundColor: Color.ECOMMERCE.LARANJA,
    color: Color.ECOMMERCE.WHITE,
    padding: 5,
    margin: 5,
    borderRadius: 10,
    width: 100,
    textAlign: "center",
  },
  labelDescricao: {
    color: Color.ECOMMERCE.CINZA_ESCURO,
    padding: 5,
    margin: 5,
    borderRadius: 10,
    width: 100,
    textAlign: "center",
  },
};

class CadastroCliente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: {
        ...new ClienteLogin(),
        permiteSerContatado: false,
        permiteReceberOferta: false,
        descricao: "Casa",
      },
      mostrarDadosPessoais: true,
      mostrarEndereco: false,
      labelMensagemCepNaoEncontrado: "",
      ...props.location.state,
    };

    this.handlerChange = this.handlerChange.bind(this);
    this.handlerBlur = this.handlerBlur.bind(this);
  }

  consultarCep() {
    axios
      .get(PATH_VIA_CEP.replace("%s", Mask.clearMask(this.state.cliente.cep)))
      .then(({ data }) => {
        if (data) {
          if (!Utils.isValueValid(data.erro)) {
            this.setState({
              cliente: {
                ...this.state.cliente,
                idCidadeIbge: data.ibge,
                bairro: data.bairro,
                complemento: data.complemento,
                cidade: data.localidade,
                logradouro: data.logradouro,
              },
              labelMensagemCepNaoEncontrado: "",
            });
          } else {
            this.setState({
              cliente: {
                ...this.state.cliente,
                idCidadeIbge: "",
                bairro: "",
                complemento: "",
                cidade: "",
                logradouro: "",
              },
              labelMensagemCepNaoEncontrado:
                "Endereço não encontrado para o CEP informado",
            });
          }
        } else {
          this.setState({
            cliente: {
              ...this.state.cliente,
              idCidadeIbge: "",
              bairro: "",
              complemento: "",
              cidade: "",
              logradouro: "",
            },
            labelMensagemCepNaoEncontrado:
              "Endereço não encontrado para o CEP informado",
          });
        }
      })
      .catch((error) => console.log(error));
  }

  renderHeader() {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <>
        <Div inline="between">
          <Div
            inline="center"
            handlerClick={() =>
              this.setState({
                mostrarDadosPessoais: true,
                mostrarEndereco: false,
              })
            }
          >
            <Div
              style={{
                backgroundColor: this.state.mostrarDadosPessoais
                  ? corPrimaria
                  : "#E5E5E5",
                color: "white",
                borderRadius: 5,
                width: 40,
                height: 40,
              }}
              inline="center"
            >
              <Label
                value="1"
                margin="1"
                family="Bold"
                style={{ fontSize: 20 }}
              />
            </Div>
            <Label
              marginLeft="3"
              marginTop="2"
              value="Dados pessoais"
              style={{ fontSize: 20, color: "#878787" }}
              family="Bold"
            />
          </Div>
          <Div
            inline="center"
            handlerClick={() => {
              if (this.validarCadastroDadosPessoais()) {
                this.setState({
                  mostrarDadosPessoais: false,
                  mostrarEndereco: true,
                });
              }
            }}
          >
            <Div
              style={{
                backgroundColor: this.state.mostrarEndereco
                  ? corPrimaria
                  : "#E5E5E5",
                color: "white",
                borderRadius: 5,
                width: 40,
                height: 40,
              }}
              inline="center"
            >
              <Label
                value="2"
                margin="1"
                family="Bold"
                style={{ fontSize: 20 }}
              />
            </Div>
            <Label
              marginLeft="3"
              marginTop="2"
              value="Endereço"
              style={{ fontSize: 20, color: "#878787" }}
              family="Bold"
            />
          </Div>
        </Div>
      </>
    );
  }

  verificarCadastroDadosEndereco() {
    const { cliente } = this.state;
    let valido = true;

    if (!Utils.isValueValid(cliente.numero)) return false;
    if (!Utils.isValueValid(cliente.bairro)) return false;
    if (!Utils.isValueValid(cliente.logradouro)) return false;
    if (!Mask.validarCep(cliente.cep)) return false;
    if (!Utils.isValueValid(cliente.descricao)) return false;

    return valido;
  }

  verificarCadastroDadosPessoais() {
    const { cliente } = this.state;
    let valido = true;

    if (String(cliente.senhaConfirmacao) !== String(cliente.senha)) {
      return false;
    }

    if (!Utils.isValueValid(cliente.senhaConfirmacao)) return false;
    if (!Utils.isValueValid(cliente.senha)) return false;
    if (!Utils.isValueValid(cliente.telefone)) return false;
    if (!Utils.isValueValid(cliente.email)) return false;
    if (!Mask.validarEmail(cliente.email)) return false;
    if (!Utils.isValueValid(cliente.cpfCnpj)) return false;
    if (!Mask.validaCpfCnpj(cliente.cpfCnpj)) return false;
    if (!Utils.isValueValid(cliente.nome)) return false;

    return valido;
  }

  validarCadastroDadosPessoais() {
    const { cliente } = this.state;
    let erro = "";

    if (String(cliente.senhaConfirmacao) !== String(cliente.senha)) {
      erro = "As senhas estão diferentes!";
    }

    if (!Utils.isValueValid(cliente.senhaConfirmacao))
      erro = "Preencha o campo senha de confirmação!";
    if (!Utils.isValueValid(cliente.senha)) erro = "Preencha o campo senha!";
    if (!Utils.isValueValid(cliente.telefone))
      erro = "Preencha o campo telefone!";
    if (!Utils.isValueValid(cliente.email)) erro = "Preencha o campo email!";
    if (!Mask.validarEmail(cliente.email)) erro = "Email inválido!";
    if (!Utils.isValueValid(cliente.cpfCnpj))
      erro = "Preencha o campo CPF / CNPJ!";
    if (!Mask.validaCpfCnpj(cliente.cpfCnpj)) erro = "CPF/CNPJ inválido!";
    if (!Utils.isValueValid(cliente.nome)) erro = "Preencha o campo nome!";

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => console.log("CANCELADO"),
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  validarCadastro() {
    const { cliente } = this.state;
    let erro = "";

    if (!Utils.isValueValid(cliente.numero))
      erro = "Preencha o campo numero do endereço de casa!";
    if (!Utils.isValueValid(cliente.bairro))
      erro = "Preencha o campo bairro do endereço de casa!";
    if (!Utils.isValueValid(cliente.logradouro))
      erro = "Preencha o campo lougradouro do endereço de casa!";
    if (!Mask.validarCep(cliente.cep)) erro = "Informe um CEP válido!";
    if (!Utils.isValueValid(cliente.descricao))
      erro = "Preencha o campo nome do endereço!";

    if (Utils.isValueValid(erro)) {
      ModalNotification.renderMessage({
        title: "Mensagem",
        body: (
          <Label
            value={erro}
            style={{ fontSize: 25, textAlign: "center" }}
            family="Light"
          />
        ),
        handlerConfirm: null,
        handlerCancel: () => console.log("CANCELADO"),
        labelButtonCancel: "Fechar",
        responsive: "5",
      });
    }

    return !Utils.isValueValid(erro);
  }

  getNomesEndereco = () => {
    return [
      new SelectValue("Casa", "Casa"),
      new SelectValue("Trabalho", "Trabalho"),
      new SelectValue("Outro", "Outro"),
    ];
  };

  renderNomesEndereco() {
    const { cliente } = this.state;
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <Div inline="start" col="12">
        <Div
          inline="center"
          style={{
            color: Color.ECOMMERCE.CINZA_ESCURO,
            padding: 5,
            margin: 5,
            borderRadius: 10,
            fontSize: 20,
          }}
        >
          <Label value="Selecione um tipo de endereço" />
          <I icon={Type.ICON.ARROW_CHEV_RIGHT} marginLeft="3" />
        </Div>

        <Div inline="between">
          {["Casa", "Trabalho", "Outro"].map((nome) => {
            return (
              <Div
                style={
                  nome === cliente.descricao
                    ? {...styles.labelDescricaoActive, backgroundColor: corPrimaria}
                    : styles.labelDescricao
                }
                handlerClick={() => {
                  this.handlerChange({
                    target: { value: nome, name: "descricao" },
                  });
                }}
                pointer
              >
                <Label
                  pointer
                  value={nome}
                  style={{ fontSize: 20, marginTop: 5 }}
                  family="Light"
                />
              </Div>
            );
          })}
        </Div>
      </Div>
    );
  }

  renderEndereco() {
    const { cliente } = this.state;

    return (
      <Div>
        {this.renderNomesEndereco()}

        <Div responsive="12">
          <Div column className="w-100">
            <InputCep
              className="w-100"
              handlerChange={this.handlerChange}
              handlerBlur={() => {
                if (Mask.validarCep(cliente.cep)) {
                  this.consultarCep();
                  this.handlerBlur({
                    target: { value: cliente.cep, name: "cep" },
                  });
                } else {
                  this.handlerBlur({
                    target: { value: null, name: "cep" },
                  });
                }
              }}
              name="cep"
              value={cliente.cep}
              placeholder="Informe o CEP"
              error={cliente.cepError}
              style={styles.input}
            />
            <If
              and
              value1={Utils.isValueValid(
                this.state.labelMensagemCepNaoEncontrado
              )}
            >
              <Label
                value={this.state.labelMensagemCepNaoEncontrado}
                colorText={Color.NODE.DANGER}
              />
            </If>
          </Div>
          <If
            and
            value1={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
          >
            <Input
              handlerChange={this.handlerChange}
              name="cidade"
              value={cliente.cidade}
              placeholder="Cidade"
              disabled
              style={styles.input50Disabled}
            />
          </If>
          <If
            and
            value1={Utils.isValueValid(
              this.state.labelMensagemCepNaoEncontrado
            )}
          >
            <ComboBox
              responsive="12"
              url="/cidade/estado/GO"
              name="idCidadeIbge"
              text="nomeCidade"
              labelPrimaryItem="Selecione uma cidade"
              style={styles.input}
              valueSelected={cliente.idCidadeIbge}
              handlerChange={this.handlerChange}
            />
          </If>
          <Input
            handlerChange={this.handlerChange}
            name="bairro"
            value={cliente.bairro}
            maxLength="100"
            placeholder="Bairro"
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            style={
              Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
                ? styles.input50
                : styles.input50Disabled
            }
          />
          <Input
            handlerChange={this.handlerChange}
            name="logradouro"
            value={cliente.logradouro}
            placeholder="Logradouro"
            maxLength="100"
            disabled={
              !Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
            }
            style={
              Utils.isValueValid(this.state.labelMensagemCepNaoEncontrado)
                ? styles.input50
                : styles.input50Disabled
            }
            col="6"
          />
          <Input
            handlerChange={this.handlerChange}
            name="numero"
            value={cliente.numero}
            maxLength="10"
            placeholder="Número"
            col="6"
            style={styles.input50}
          />
          <Input
            handlerChange={this.handlerChange}
            name="complemento"
            maxLength="100"
            value={cliente.complemento}
            placeholder="Complemento"
            style={styles.input50}
          />

          <Input
            handlerChange={this.handlerChange}
            name="referencia"
            value={cliente.referencia}
            placeholder="Referência"
            style={styles.input50}
          />
        </Div>
        <Div inline="center"></Div>
      </Div>
    );
  }

  componentDidMount() {
    let input = document.getElementById("inputNomeCompletoCadastroCliente");
    if (Utils.isValueValid(input)) {
      input.focus();
    }
  }

  handlerChange(event) {
    const { name, value } = event.target;
    this.setState({
      cliente: {
        ...this.state.cliente,
        [name]: value,
      },
    });
  }

  handlerBlur(event) {
    const { name, value } = event.target;
    let cliente = this.state.cliente;
    let keyError = String(name).concat("Error");
    let error = null;

    if (!Utils.isValueValid(value)) {
      switch (name) {
        case "nome":
          error = "O nome é obrigatório!";
          break;

        case "telefone":
          error = "O telefone deve ser válido!";
          break;

        case "cpfCnpj":
          error = "O CPF/CNPJ deve ser válido!";
          break;

        case "email":
          error = "O email deve ser válido!";
          break;

        case "senhaConfirmacao":
          error = "As senhas devem ser iguais!";
          break;
        case "cep":
          error = "Informe um CEP válido";
          break;

        default:
          error = "";
          break;
      }
    } else {
      keyError = String(name).concat("Error");
      error = null;
    }

    this.setState({
      cliente: {
        ...cliente,
        [keyError]: error,
      },
    });
  }

  renderDadosPessoais() {
    const { cliente } = this.state;

    return (
      <Div>
        <Div marginTop="5">
          <Input
            id="inputNomeCompletoCadastroCliente"
            handlerChange={this.handlerChange}
            handlerBlur={this.handlerBlur}
            name="nome"
            value={cliente.nome}
            placeholder="Nome completo"
            style={styles.input}
            error={cliente.nomeError}
          />
        </Div>
        <Input
          handlerChange={(e) => {
            Mask.maskCpfCnpj(e);
            this.handlerChange(e);
          }}
          handlerBlur={(e) => {
            if (Mask.validaCpfCnpj(e.target.value)) {
              this.handlerBlur(e);
            } else {
              this.handlerBlur({
                target: { value: null, name: e.target.name },
              });
            }
          }}
          name="cpfCnpj"
          value={cliente.cpfCnpj}
          placeholder="CPF / CNPJ"
          error={cliente.cpfCnpjError}
          style={styles.input}
        />
        <Input
          handlerChange={this.handlerChange}
          handlerBlur={(e) => {
            if (Mask.validarEmail(e.target.value)) {
              this.handlerBlur(e);
            } else {
              this.handlerBlur({
                target: { value: null, name: e.target.name },
              });
            }
          }}
          name="email"
          value={cliente.email}
          placeholder="Email"
          error={cliente.emailError}
          style={styles.input}
        />
        <Input
          handlerChange={(e) => {
            Mask.maskPhone(e);
            this.handlerChange(e);
          }}
          handlerBlur={this.handlerBlur}
          name="telefone"
          value={cliente.telefone}
          placeholder="Telefone"
          error={cliente.telefoneError}
          style={styles.input}
        />
        <Input
          handlerChange={this.handlerChange}
          handlerBlur={this.handlerBlur}
          error={cliente.senhaError}
          name="senha"
          value={cliente.senha}
          placeholder="Senha"
          type="password"
          style={styles.input}
        />
        <Input
          handlerChange={this.handlerChange}
          handlerBlur={(e) => {
            if (String(cliente.senha) !== String(cliente.senhaConfirmacao)) {
              this.handlerBlur({
                target: { value: null, name: "senhaConfirmacao" },
              });
            } else {
              this.handlerBlur(e);
            }
          }}
          name="senhaConfirmacao"
          value={cliente.senhaConfirmacao}
          error={cliente.senhaConfirmacaoError}
          placeholder="Confirmar senha"
          type="password"
          style={styles.input}
        />
        <Div style={styles.containerCheckBox} inline="between">
          <Checkbox
            label="Quero ser contatado pela loja"
            handlerChange={(e) =>
              this.handlerChange({
                target: { name: "permiteSerContatado", value: e.checked },
              })
            }
            margin="2"
            checked={cliente.permiteSerContatado}
            //danger
          />
          <Checkbox
            label="Desejo receber ofertas da loja"
            handlerChange={(e) =>
              this.handlerChange({
                target: { name: "permiteReceberOferta", value: e.checked },
              })
            }
            margin="2"
            checked={cliente.permiteReceberOferta}
            //danger
          />
        </Div>
      </Div>
    );
  }

  continuarCadastroCliente() {
    if (this.validarCadastroDadosPessoais()) {
      this.setState({
        mostrarDadosPessoais: false,
        mostrarEndereco: true,
      });
    }
  }

  voltarCancelarCadastro() {
    if (this.state.mostrarEndereco) {
      this.setState({ mostrarDadosPessoais: true, mostrarEndereco: false });
    } else {
      this.props.history.replace("/");
    }
  }

  getDataCliente(cliente) {
    return {
      nome: cliente.nome,
      cpfCnpj: Mask.clearMask(cliente.cpfCnpj),
      telefone: Mask.clearMask(cliente.telefone),
      email: cliente.email,
      senha: cliente.senha,
      permiteReceberOferta: cliente.permiteReceberOferta,
      permiteSerContatado: cliente.permiteSerContatado,
      tipoPessoa:
        Mask.clearMask(cliente.cpfCnpj).length > 11 ? "JURIDICA" : "FISICA",
      enderecos: [
        {
          descricao: cliente.descricao,
          logradouro: cliente.logradouro,
          numero: cliente.numero,
          complemento: cliente.complemento,
          bairro: cliente.bairro,
          idCidadeIbge: cliente.idCidadeIbge,
          cep: Mask.clearMask(cliente.cep),
          referencia: cliente.referencia,
          principal: true,
        },
      ],
    };
  }

  logarUsuario() {
    const { cliente } = this.state;

    Http.post("/cliente/login", { login: cliente.email, senha: cliente.senha })
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            //localStorage.setItem(Keys.KEY_USUARIO_LOJA_WEB, JSON.stringify(data));
            UsuarioUtils.setUsuarioLojaWeb(data);
            this.props.history.replace("/");
          }
        }
      })
      .catch((erro) => console.log(erro));
  }

  cadastrarCliente() {
    Http.post("/cliente", this.getDataCliente(this.state.cliente))
      .then(({ status, data }) => {
        if (status) {
          if (data.status) {
            this.logarUsuario();
          } else {
            ModalNotification.renderMessage({
              title: "Falha ao realizar o cadastro",
              body: (
                <Label
                  value={data.mensagem}
                  style={{ fontSize: 25, textAlign: "center" }}
                  family="Light"
                />
              ),
              handlerConfirm: null,
              handlerCancel: () => console.log("CANCELADO"),
              labelButtonCancel: "Fechar",
              responsive: "5",
            });
          }
        }
      })
      .catch((error) => console.log(error));
  }

  render() {
    let corPrimaria = EmpresaUtils.getCorPrimaria(this.state.empresa);
    return (
      <>
        <Image
          src={this.state.logoEmpresa}
          style={{ position: "absolute", top: 10, left: 10 }}
          width="180px"
          handlerClick={() => this.props.history.replace("/")}
        />
        <Div
          inline="center"
          style={{
            backgroundColor: corPrimaria,
            padding: 10,
            borderRadius: 5,
            position: "absolute",
            top: 10,
            right: 10,
            color: Color.ECOMMERCE.WHITE,
          }}
          handlerClick={() => this.voltarCancelarCadastro()}
          pointer
        >
          <Label
            pointer
            value={
              this.state.mostrarDadosPessoais ? "Cancelar cadastro" : "Voltar"
            }
            family="Bold"
            margin="2"
          />
          <I
            icon={
              this.state.mostrarDadosPessoais
                ? Type.ICON.CLOSEX
                : Type.ICON.ARROW_CIRCLE_LEFT
            }
            pointer
            sizeIcon="2"
            style={{ color: Color.ECOMMERCE.WHITE }}
          />
        </Div>
        <Div column style={{ paddingBottom: 100 }}>
          <Div responsive="8">
            {this.renderHeader()}
            <If and value1={this.state.mostrarDadosPessoais}>
              <Div marginTop="2">{this.renderDadosPessoais()}</Div>
            </If>
            <If and value1={this.state.mostrarEndereco}>
              <Div marginTop="2">{this.renderEndereco()}</Div>
            </If>
          </Div>
        </Div>
        <Div style={styles.containerBottom}>
          <Div
            responsive="12"
            inline="center"
            style={
              (this.state.mostrarDadosPessoais &&
                this.verificarCadastroDadosPessoais()) ||
              (this.state.mostrarEndereco &&
                this.verificarCadastroDadosEndereco())
                ? {
                    ...styles.containerContinuarActive,
                    backgroundColor: corPrimaria,
                  }
                : styles.containerContinuar
            }
            pointer
            handlerClick={() => {
              if (this.state.mostrarEndereco && this.validarCadastro()) {
                this.cadastrarCliente();
              } else {
                this.continuarCadastroCliente();
              }
            }}
          >
            <Label
              value={
                this.state.mostrarEndereco ? "Finalizar cadastro" : "Continuar"
              }
              family="SemiBold"
            />
            <I
              icon={Type.ICON.ARROW_CHEV_RIGHT}
              marginLeft="2"
              marginBottom="2"
            />
          </Div>
        </Div>
      </>
    );
  }
}

export default CadastroCliente;
